document.addEventListener("DOMContentLoaded", function(){
  AOS.init();

  $(".page-header").sticky({ topSpacing: 0 });

  $('nav#menu').mmenu({
    drag: true,
    navbar: {
      title: "選單"
    },
    pageScroll: {
      scroll: true,
      update: true
    },
    'extensions': [
      'theme-dark',
      'fx-menu-fade',
      'fx-listitems-slide'
    ]
  }, {
    classNames: {
      fixedElements: {
        fixed: 'fix',
        sticky: 'is-sticky'
      }
    }
  })

  // var divElements = document.getElementsByClassName("kv-zoom-slider");
  // var delay = 4500;
  // var currentIndex = 0;
  // var preIndex = divElements.length - 1;
  // divElements[0].classList.add("show");
  // setInterval(function () {
  //   divElements[preIndex].classList.remove("fade-out");
  //   divElements[currentIndex].classList.remove("show");
  //   currentIndex = (currentIndex + 1) % divElements.length;
  //   preIndex = currentIndex == 0 ? divElements.length - 1 : currentIndex - 1
  //   divElements[preIndex].classList.add("fade-out");
  //   divElements[currentIndex].classList.add("show");
  // }, delay);

});

function smoothScrollto (id) {
  let target = document.querySelector(`#${id}`)
  target.scrollIntoView({
    behavior: 'smooth'
  });
}


